import ComingSoon from "react-coming-soon";
import './App.css';

function App() {
  return (
    <div className="App">
      <ComingSoon
        bgColor="#f5f6f8"
        title="Coming Soon "
        textColor="#2d024c"
        date="Sun Jun 30 2023 00:00:00 GMT+0100 (West Africa Standard Time)"
        illustration="development"
      />
    </div>
  );
}

export default App;
